<script>
import { mapActions, mapGetters } from "vuex";

import tnVue from "@/mixins/tn.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

import PriceListItems from "@/components/price-list-items/price-list-items.vue";
import TablePriceFilter from "@/components/modals/price-list-compare/table-price-filter.vue";
import PriceListViewingAccessPermission from "@/views/price-list/price-list-viewing-access-permission.vue";
import PriceListFavoriteBtn from "./price-list-favorite-btn.vue";
import PriceListAlertBtn from "./price-list-alert-btn.vue";
import ShowPharmacyRequirements from "@/components/show-pharmacy-requirements.vue";

const urlItemsByPrice = `PriceListLineItem`;

export default {
  components: {
    PriceListItems,
    TablePriceFilter,
    PriceListViewingAccessPermission,
    PriceListFavoriteBtn,
    PriceListAlertBtn,
    ShowPharmacyRequirements,
  },

  data: (vm) => ({
    ln: "price_list.show",
    uniqKey: "price-list-show",

    sortBy: "productName",
    isShowDelete: false,
    urlItemsByPrice,

    priceFilterData: vm.$getFilterData() || {},
    isPriceFilter: false,
    isViewingAccessPermission: false,
    isShowFavorite: undefined,
    isShowAlert: undefined,
    isShowOrganizationStatistic: undefined,
    isShowOrganizationStatisticReject: undefined,
    isShowItems: false,
    isHide: null,
    isMyMarkup: false,
    markupText: null,
  }),

  mixins: [tnVue, TableResizeVue, TableNavFunctionsVue],

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("price-list", { priceListObject: "GET_BY_GUID" }),
    ...mapGetters("price-list-items", ["GET", "GET_COUNT", "GET_TOTALS"]),

    entries: (vm) => vm.GET(urlItemsByPrice),
    entriesCount: (vm) => vm.GET_COUNT(urlItemsByPrice),
    entriesTotals: (vm) => vm.GET_TOTALS(urlItemsByPrice),

    tableHeight() {
      return this.size.height - (this.priceListObject.isCurrent ? 210 : 300);
    },

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "number",
          width: 10,
          sortable: false,
          _resize: false,
          class: "disable-resize",
        },
        {
          text: "#",
          value: "favorite-and-alert",
          width: 10,
          sortable: false,
          _slot: true,
          _resize: false,
          align: "center",
          class: "disable-resize",
        },
        {
          text: vm.tn("table.productName"),
          value: "productName",
          width: vm.$dxcs.productName,
          _required: true,
        },
        {
          text: vm.tn("table.manufacturerName"),
          value: "manufacturerName",
          width: vm.$dxcs.manufacturer,
          _required: true,
        },
        {
          text: vm.tn("table.shelfLife"),
          value: "shelfLife",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.priceTypeName"),
          value: "priceTypeName",
          width: vm.$dxcs.priceTypeName,
        },
        {
          text: vm.tn("table.amount"),
          value: "amount",
          _filter: (v) => vm.$sum(v),
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceWholeSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.amount,
          _align: "right",
          _required: true,
        },
        {
          text: vm.tn("table.discount_amount"),
          value: "amountDiscount",
          _filter: (v) => vm.$sum(v),
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceWholeSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.amount,
          _align: "right",
        },
        {
          text: vm.tn("table.my_selling_price"),
          value: "mySellingPrice",
          sortable: false,
          visible: vm.isMyMarkup,
          _filter: (v, item) => {
            const a = item.amountDiscount || item.amount;
            const b = vm.markupText;
            const diff = Math.round(+(a * (b / 100)).toFixed(2));
            const sellingPrice = Math.round(+(a + diff).toFixed(2));

            if (diff) {
              let vl = `<span class="green--text">(+${vm.$sum(diff)})</span> `;

              const refRtSlPrice = item.productReferentPriceRetailSalePrice;
              if (sellingPrice && refRtSlPrice && sellingPrice > refRtSlPrice) {
                return `${vm.$sum(vl)} <span class="red--text">${vm.$sum(sellingPrice)}</span>`;
              }
              return `${vm.$sum(vl)} ${vm.$sum(sellingPrice)}`;
            }

            return `${vm.$sum(sellingPrice)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceDate"),
          value: "productReferentPriceDate",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.$t("table.productReferentPriceWholeSalePrice"),
          value: "productReferentPriceWholeSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceWholeSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceRetailSalePrice"),
          value: "productReferentPriceRetailSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceRetailSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.tn("table.load"),
          value: "load",
        },
        {
          text: vm.tn("table.actions.count"),
          value: "actions",
          class: "disable-resize",
          sortable: false,
          _resize: false,
          width: 85,
        },
      ]
        .map((x) => {
          if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
          return x;
        })
        .filter((e) => e.visible != false),

    favoriteBtnTitle() {
      if (this.isShowFavorite) {
        return this.tn("favorite.show_not_favorite_btn");
      } else if (this.isShowFavorite == false) {
        return this.tn("favorite.show_all_btn");
      } else {
        return this.tn("favorite.show_favorite_btn");
      }
    },

    alertBtnTitle() {
      if (this.isShowAlert) {
        return this.tn("alert.show_not_alert_btn");
      } else if (this.isShowAlert == false) {
        return this.tn("alert.show_all_btn");
      } else {
        return this.tn("alert.show_alert_btn");
      }
    },

    organizationStatisticBtnTitle() {
      if (this.isShowOrganizationStatistic) {
        return this.tn("organization_statistic.show_not_organization_statistic_btn");
      } else if (this.isShowOrganizationStatistic == false) {
        return this.tn("organization_statistic.show_all_btn");
      } else {
        return this.tn("organization_statistic.show_organization_statistic_btn");
      }
    },

    organizationStatisticRejectBtnTitle() {
      if (this.isShowOrganizationStatisticReject) {
        return this.tn("organization_statistic_reject.show_not_organization_statistic_reject_btn");
      } else if (this.isShowOrganizationStatisticReject == false) {
        return this.tn("organization_statistic_reject.show_all_reject_btn");
      } else {
        return this.tn("organization_statistic_reject.show_organization_statistic_reject_btn");
      }
    },

    priceListGuid: (vm) => vm.$route.params.guid,

    isPriceFilterBadge() {
      const values = Object.values(this.priceFilterData);
      const filters = values.filter((x) => x === 0 || `${x || ""}`.length);
      return filters.length;
    },
  },

  async created() {
    await this.GET_BY_GUID_API({ guid: this.priceListGuid });
  },

  watch: {
    selected(v) {
      this.isShowItems = false;
      this.$nextTick(() => {
        if (v?.priceListLineItemGuid) this.isShowItems = true;
        this.$refs.table2?.getEntries({ reset: true });
      });
    },
  },

  methods: {
    ...mapActions("price-list", ["GET_BY_GUID_API"]),
    ...mapActions("price-list-items", ["GET_API"]),

    onPriceFilter(priceFilterData) {
      this.priceFilterData = priceFilterData;
      this.$setFilterData(priceFilterData);
      this.$refs.table1?.getEntries({ reset: true });
    },

    getPrice(params) {
      params.filterData = this.priceFilterData;
      params.filterData.isAlert = this.isShowAlert;
      params.filterData.isFavorite = this.isShowFavorite;
      params.filterData.showByPriceListViewerOrganizationStatistic = this.isShowOrganizationStatistic;
      params.filterData.showByPriceListViewerProductRejects = this.isShowOrganizationStatisticReject;
      return this.GET_API(params);
    },

    async onClose() {
      this.isViewingAccessPermission = false;
      await this.GET_BY_GUID_API({ guid: this.priceListGuid });
    },

    onShowFavorite() {
      if (this.isShowFavorite) this.isShowFavorite = false;
      else if (this.isShowFavorite == false) this.isShowFavorite = undefined;
      else this.isShowFavorite = true;
      this.$refs.table1?.getEntries({ reset: true });
    },

    onShowAlert() {
      if (this.isShowAlert) this.isShowAlert = false;
      else if (this.isShowAlert == false) this.isShowAlert = undefined;
      else this.isShowAlert = true;
      this.$refs.table1?.getEntries({ reset: true });
    },

    onShowOrganizationStatistic() {
      if (this.isShowOrganizationStatistic) this.isShowOrganizationStatistic = false;
      else if (this.isShowOrganizationStatistic == false) this.isShowOrganizationStatistic = undefined;
      else this.isShowOrganizationStatistic = true;
      this.$refs.table1?.getEntries({ reset: true });
    },

    onShowOrganizationStatisticReject() {
      if (this.isShowOrganizationStatisticReject) this.isShowOrganizationStatisticReject = false;
      else if (this.isShowOrganizationStatisticReject == false) this.isShowOrganizationStatisticReject = undefined;
      else this.isShowOrganizationStatisticReject = true;
      this.$refs.table1?.getEntries({ reset: true });
    },

    async onSendToBasket() {
      this.$store.dispatch("SET_LOADING", true);

      const url = "PriceListViewerOrganizationStatistic/Basket";
      const params = { priceListGuid: this.priceListGuid };
      const res = (await this.$axios.$post(url, params))?.data;

      if (!this.$number(res?.count)) {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.tn("no_products_added"),
          color: "info",
        });
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.tn("added_to_basket", { count: this.$number(res?.count) }),
          color: "info",
        });
        this.$router.replace("/basket");
      }

      this.$store.dispatch("SET_LOADING", false);
    },
  },
};
</script>

<template>
  <v-container fluid>
    <TablePriceFilter
      :ln="ln + '.table.filter'"
      v-if="isPriceFilter"
      :value="priceFilterData"
      @close="isPriceFilter = false"
      @apply="onPriceFilter"
    />

    <PriceListViewingAccessPermission
      v-if="isViewingAccessPermission"
      :priceListObject="priceListObject"
      @close="onClose"
    />

    <price-list-items
      :GET_API="getPrice"
      :entries="entries"
      @select="(v) => (selected = v)"
      @markup="(v) => (markupText = v)"
      @isMyMarkup="(v) => (isMyMarkup = v)"
      :entriesCount="entriesCount"
      :tableHeaders="tableHeaders"
      :ln="ln"
      :sortBy="sortBy"
      :tableHeight="isHide ? tableHeight / 2.06 : tableHeight / 1"
      :uniqKey="uniqKey"
      :url="urlItemsByPrice"
      isShowTotals
      ref="table1"
      :searchBoxHotKey="113"
      :isExclusiveProductBtnDisabled="false"
      :v-resize-table="{ tableHeaders: tableHeaders, callback: onUpdateTdSizes }"
    >
      <template #favorite-and-alert="slotProps">
        <div class="d-flex align-center">
          <price-list-favorite-btn
            v-if="false"
            :ln="ln"
            :item="slotProps.item"
          />
          <price-list-alert-btn
            v-if="false"
            :ln="ln"
            :item="slotProps.item"
          />

          <v-btn
            v-if="slotProps.item.hasStatistics"
            color="transparent"
            icon
            x-small
          >
            <img
              width="18"
              height="18"
              src="@/assets/svg/potrebnost.svg"
              alt=""
            />
          </v-btn>

          <v-btn
            v-if="slotProps.item.hasRejects"
            color="primary"
            icon
            x-small
          >
            <img
              src="@/assets/svg/reject.svg"
              alt=""
              width="18"
              height="18"
            />
          </v-btn>
        </div>
      </template>
      <template #title>
        <h3>
          <v-icon
            small
            @click="$windowOpen('price-list-provider', priceListObject.providerGuid, priceListObject.providerName, 650, 800, 'preview=1')"
          >
            mdi-information-outline
          </v-icon>

          {{ priceListObject.providerName }}
        </h3>
        <small>
          <b> {{ tn("updated") }}: </b>
          {{ priceListObject.timePublished | dateTime }}
          <template v-if="priceListObject.expiryDate">
            - <b> {{ tn("expiryDate") }}: </b>
            {{ priceListObject.expiryDate | date }}
          </template>
        </small>
      </template>

      <div
        v-if="priceListObject.isCurrent == false"
        class="mt-1 mb-3 error px-3 py-2 white--text price-list-show-expiry-alert"
      >
        <div>
          <div class="title">
            {{ tn("expiry_date_alert") }}
          </div>
          <template v-if="priceListObject.currentPriceListGuid">
            {{ tn("has_new_price_alert") }}
          </template>
        </div>
        <div>
          <v-btn
            v-if="priceListObject.currentPriceListGuid"
            elevation="0"
            class="transform-none"
            :to="`/price-list/${priceListObject.currentPriceListGuid}`"
            replace
          >
            {{ tn("show_prays_btn") }}
          </v-btn>
        </div>
      </div>

      <template #actions>
        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          :title="tn('add_qty_stock_need_to_basket_btn')"
          @click="onSendToBasket"
        >
          <v-icon> mdi-cart-plus </v-icon>
        </v-btn>

        <div class="px-1" />

        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          :title="favoriteBtnTitle"
          @click="onShowFavorite"
        >
          <v-icon v-if="isShowFavorite"> mdi-star </v-icon>
          <v-icon v-else-if="isShowFavorite == undefined"> mdi-star-half-full </v-icon>
          <v-icon v-else> mdi-star-outline </v-icon>
        </v-btn>

        <div class="mx-1" />

        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          :title="alertBtnTitle"
          @click="onShowAlert"
        >
          <v-icon v-if="isShowAlert"> mdi-bell </v-icon>
          <img
            v-else-if="isShowAlert == undefined"
            src="@/assets/svg/mdi-bell-outline.svg"
            alt=""
          />
          <v-icon v-else> mdi-bell-outline </v-icon>
        </v-btn>

        <div
          class="mx-1"
          v-if="priceListObject.showPermissionButton"
        />

        <v-btn
          v-if="priceListObject.showPermissionButton"
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          :title="tn('permission_btn')"
          @click="isViewingAccessPermission = true"
        >
          <v-icon> mdi-account-key-outline </v-icon>
        </v-btn>

        <div class="px-1" />

        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          :title="organizationStatisticBtnTitle"
          @click="onShowOrganizationStatistic"
        >
          <img
            v-if="isShowOrganizationStatistic"
            src="@/assets/svg/potrebnost.svg"
            alt=""
          />
          <img
            v-else-if="isShowOrganizationStatistic == undefined"
            src="@/assets/svg/potrebnost-off.svg"
            alt=""
          />
          <img
            v-else
            src="@/assets/svg/potrebnost-outlined.svg"
            alt=""
          />
        </v-btn>

        <div class="px-1" />

        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          :title="organizationStatisticRejectBtnTitle"
          @click="onShowOrganizationStatisticReject"
        >
          <img
            v-if="isShowOrganizationStatisticReject"
            src="@/assets/svg/reject.svg"
            alt=""
          />
          <img
            v-else-if="isShowOrganizationStatisticReject == undefined"
            src="@/assets/svg/reject-off.svg"
            alt=""
          />
          <img
            v-else
            src="@/assets/svg/reject-outlined.svg"
            alt=""
          />
        </v-btn>

        <v-badge
          class="mx-2"
          dot
          overlap
          color="error"
          :value="isPriceFilterBadge"
        >
          <v-btn
            color="primary"
            min-width="40"
            width="40"
            height="40"
            min-height="40"
            outlined
            @click="isPriceFilter = true"
            :title="tn('filter_btn')"
          >
            <v-icon> mdi-filter </v-icon>
          </v-btn>
        </v-badge>
      </template>

      <template #body-append="bodyProps">
        <td
          v-for="(header, i) in bodyProps.headers"
          :key="i"
        >
          <div
            v-if="header.value == 'number'"
            class="text-nowrap text-center"
          >
            {{ entriesCount | sum }}
          </div>

          <div
            v-else-if="header.value == 'amount'"
            class="text-nowrap text-right"
          >
            {{ entriesTotals.totalAmount | sum }}
          </div>

          <div
            v-else-if="header.value == 'actions'"
            class="text-nowrap text-right"
          >
            {{ entriesTotals.totalQuantity | sum }}
          </div>

          <div v-else />
        </td>
      </template>
    </price-list-items>

    <ShowPharmacyRequirements
      v-if="isShowItems"
      ref="table2"
      :tableHeight="tableHeight / 2.2"
      :priceListLineItemGuid="selected?.priceListLineItemGuid"
      :amount="selected?.amountDiscount || selected?.amount"
      :uniqKey="ln"
      @isHide="(e) => (isHide = e)"
    />
  </v-container>
</template>
