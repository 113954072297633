<script>
import { mapActions } from "vuex";
import tnVue from "@/mixins/tn.vue";

export default {
  props: {
    item: Object,
    ln: String,
    disabled: Boolean,
  },

  data: () => ({
    isLoading: false,
  }),

  mixins: [tnVue],

  methods: {
    ...mapActions("price-list-items", { exclusiveProviderApi: "EXCLUSIVE_PROVIDER_API" }),

    async onExclusiveProvider() {
      this.isLoading = true;
      await this.exclusiveProviderApi({ ...this.item });
      this.isLoading = false;
    },
  },
};
</script>

<template>
  <div>
    <v-btn
      v-if="!disabled"
      :loading="isLoading"
      :color="item.isExclusiveProvider ? 'primary' : undefined"
      :title="item.isExclusiveProvider ? tn('table.actions.exclusive_remove_btn') : tn('table.actions.exclusive_add_btn')"
      @click="onExclusiveProvider"
      icon
      x-small
      class="mr-1"
    >
      <v-icon>
        {{ item.isExclusiveProvider ? "mdi-message-plus" : "mdi-message-outline" }}
      </v-icon>
    </v-btn>

    <v-btn
      v-else
      :loading="isLoading"
      :color="item.isExclusiveProvider ? 'primary' : undefined"
      :title="item.isExclusiveProvider ? tn('table.actions.exclusive') : tn('table.actions.not_exclusive')"
      icon
      x-small
      class="mr-1"
    >
      <v-icon>
        {{ item.isExclusiveProvider ? "mdi-message-plus" : "mdi-message-outline" }}
      </v-icon>
    </v-btn>
  </div>
</template>
