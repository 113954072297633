import { render, staticRenderFns } from "./price-list-compare.vue?vue&type=template&id=dcf320a2&scoped=true"
import script from "./price-list-compare.vue?vue&type=script&lang=js"
export * from "./price-list-compare.vue?vue&type=script&lang=js"
import style0 from "./price-list-compare.vue?vue&type=style&index=0&id=dcf320a2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcf320a2",
  null
  
)

export default component.exports