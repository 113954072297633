<script>
import { mapActions, mapGetters } from "vuex";

import tnVue from "@/mixins/tn.vue";
import TableResizeVue from "@/mixins/table-resize.vue";

import PriceListItems from "@/components/price-list-items/price-list-items.vue";
import ProviderList from "./components/provider-list.vue";
import ShowPharmacyRequirements from "@/components/show-pharmacy-requirements.vue";
import AddOptimalBasketModal from "./components/add-optimal-basket-modal.vue";
import PermissionConfirmDialog from "@/components/modals/permission-confirm-dialog.vue";
import ExclusiveProviderBtn from '@/components/price-list-items/exclusive-provider-btn.vue'
//import ConfirmDialog from "@/components/modals/confirm-dialog.vue";

const urlResultItems = `PriceListViewerProductSelection/Result`;

export default {
  components: {
    PriceListItems,
    ProviderList,
    ShowPharmacyRequirements,
    AddOptimalBasketModal,
    PermissionConfirmDialog,
    ExclusiveProviderBtn,
  },

  data: () => ({
    ln: "selection_auto.result",
    uniqKey: "selection-auto-result",

    itemData: {},
    selectedRows: [],
    filteredHeaders: [],

    urlResultItems,

    isDrawer: true,

    isAddOptimalBasket: false,

    isPermissionAlert: false,
    isShowItems: false,
    isShowOrganizationStatistic: undefined,
    isHide: null,
    tryCount: null,
  }),

  mixins: [tnVue, TableResizeVue],

  computed: {
    ...mapGetters("auth", ["GET_USER", "GET_USER_PERMISSIONS"]),

    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("price-list-items", ["GET", "GET_COUNT", "GET_TOTALS"]),

    entriesByProduct: (vm) => vm.GET(urlResultItems),
    entriesCountByProduct: (vm) => vm.GET_COUNT(urlResultItems),

    tableHeight: (vm) => vm.size.height - 160,

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "number",
          width: 10,
          sortable: false,
          _resize: false,
          class: "disable-resize",
        },
        {
          text: vm.tn("table.status"),
          value: "_add_btn",
          _slot: true,
          _required: true,
          _align: "center",
          _resize: false,
          width: 10,
        },
        {
          text: vm.tn("table.priceProvider"),
          value: "providerName",
          _slot: true,
          width: vm.$dxcs.priceListProviderName,
        },
        {
          text: vm.tn("table.priceTypeName"),
          value: "priceTypeName",
          width: vm.$dxcs.priceTypeName,
        },
        {
          text: vm.tn("table.productName"),
          value: "productName",
          width: vm.$dxcs.productName,
        },
        {
          text: vm.tn("table.manufacturerName"),
          value: "manufacturerName",
          width: vm.$dxcs.manufacturer,
        },
        {
          text: vm.tn("table.shelfLife"),
          value: "shelfLife",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.load"),
          value: "load",
        },
        {
          text: vm.tn("table.discount_amount"),
          value: "amountDiscount",
          _filter: (v, item) => {
            const val = vm.$sum(v);
            if (!val) return;

            const a = item.salePrice;
            if (a) {
              const c = (a / v) * 100;
              const diff = -(c - 100).toFixed(2);
              if (diff) {
                let vl = `<span class="red--text">(${vm.$sum(diff)}%)</span>`;
                if (diff < 0) vl = `<span class="green--text">(${vm.$sum(diff)}%)</span>`;
                return `${vl} ${val}`;
              }
            }

            return `${val}`;
          },
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceWholeSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.amount,
          _align: "right",
        },
        {
          text: vm.tn("table.amount"),
          value: "amount",
          _filter: (v, item) => {
            const val = vm.$sum(v);
            if (!val) return;

            const a = item.salePrice;
            if (a) {
              const c = (a / v) * 100;
              const diff = -(c - 100).toFixed(2);
              if (diff) {
                let vl = `<span class="red--text">(${vm.$sum(diff)}%)</span>`;
                if (diff < 0) vl = `<span class="green--text">(${vm.$sum(diff)}%)</span>`;
                return `${vl} ${val}`;
              }
            }

            return `${val}`;
          },
          _getColor: (v, item) => {
            const mxp = item.productReferentPriceWholeSalePrice;
            if (v && mxp && v > mxp) return "red";
          },
          width: vm.$dxcs.amount,
          _align: "right",
        },
        {
          text: vm.tn("table.salePrice"),
          value: "salePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) return;
            const a = item.amountDiscount || item.amount;
            if (a) {
              const diff = val - a;
              if (diff) {
                let vl = `<span class="green--text">(+${vm.$sum(diff)})</span>`;
                if (diff < 0) vl = `<span class="red--text">(${vm.$sum(diff)})</span>`;
                return `${vl} ${vm.$sum(val)}`;
              }
            }
            return vm.$sum(`${val}`);
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceDate"),
          value: "productReferentPriceDate",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.$t("table.productReferentPriceWholeSalePrice"),
          value: "productReferentPriceWholeSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceWholeSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.$t("table.productReferentPriceRetailSalePrice"),
          value: "productReferentPriceRetailSalePrice",
          _filter: (v, item) => {
            const val = vm.$number(v);
            if (!val) {
              return vm.$sum(val);
            }

            const a = vm.$number(item.amountDiscount) || vm.$number(item.amount);
            const b = item.productReferentPriceRetailSalePrice;
            const c = (b / a) * 100;
            const diff = +(c - 100).toFixed(2);
            const diffText = diff ? (diff < 0 ? `<span class="red--text">(${vm.$sum(diff)}%)</span>` : `<span class="green--text">(${vm.$sum(diff)}%)</span>`) : "";

            return `${diffText} ${vm.$sum(val)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.tn("table.selling"),
          value: "saleQuantityDay30",
          width: vm.$dxcs.qtyString,
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: vm.tn("table.stockQuantity"),
          value: "stockQuantity",
          width: vm.$dxcs.qty,
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: vm.tn("table.stockNeed"),
          value: "stockNeed",
          width: vm.$dxcs.qty,
          _filter: (v) => vm.$sum(v),
          _align: "right",
        },
        {
          text: vm.tn("table.actions.count"),
          value: "actions",
          class: "disable-resize",
          sortable: false,
          _resize: false,
          width: 85,
        },
      ].map((x) => {
        if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
        return x;
      }),

    getTableHeight() {
      let tbHeight = null;

      switch (this.isShowOrganizationStatistic) {
        case true:
          tbHeight = this.isHide ? this.tableHeight / 2.12 : this.tableHeight / 1.07;
          break;
        case false:
          tbHeight = this.tableHeight;
          break;
        case undefined:
          tbHeight = this.isHide ? this.tableHeight / 2.12 : this.tableHeight / 1.07;
          break;
      }

      if (!this.itemData?.linkedProductGuid) tbHeight = this.tableHeight;
      return tbHeight;
    },
  },

  watch: {
    selectedRows() {
      this.$refs.table2?.getEntries({ reset: true });
    },
  },

  mounted() {
    document.addEventListener("keydown", this.onDocumentKeyup);
  },

  destroyed() {
    document.removeEventListener("keydown", this.onDocumentKeyup);
  },

  methods: {
    ...mapActions("price-list-items", ["GET_API"]),
    ...mapActions("auth", ["GET_PERMISSIONS_LIST"]),

    getResultItems(params) {
      params.options.ids = this.selectedRows.map((x) => x.id);
      params.options.guid = this.$route.params.resultGuid;
      return this.GET_API(params);
    },

    onDocumentKeyup(event) {
      if (event.code != "Tab") return;
      event.preventDefault();

      const table1 = this.$refs.table1;
      const table2 = this.$refs.table2;
      if (!this.isDrawer || table1?.checkFocused()) {
        table2.isSavePosition = true;
        table2.setFocusToItem();
      } else {
        table1.isSavePosition = true;
        table1.setFocusToItem();
      }
    },

    onRefresh() {
      this.$refs.table1?.getEntries({ reset: true });
      this.$refs.table2?.getEntries({ reset: true });
    },

    onAdd({ item }) {
      this.$refs.table2.onSelect({ item });
      this.$nextTick(() => {
        this.$refs.table2.onAdd({ qty: item.stockNeed || 1 });
      });
    },

    async onShowAddOptimalBasket() {
      if (!this.GET_USER.isTrial) {
        this.isAddOptimalBasket = true;
      } else {
        this.$store.dispatch("SET_LOADING", true);
        await this.GET_PERMISSIONS_LIST();
        this.$store.dispatch("SET_LOADING", false);

        this.tryCount = this.$getObjectParam("CanAutoAddBasket", this.GET_USER_PERMISSIONS);
        if (this.$number(this.tryCount)) {
          this.isPermissionAlert = true;
        } else {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.$t("permission_confirm_dialog.permission_alert"),
            color: "error",
          });
        }
      }
    },

    async onAddOptimalBasket({ setLoading, close, payload }) {
      setLoading(true);
      const guid = this.$route.params.resultGuid;
      const url = `PriceListViewerProductSelection/${guid}/AddToBasketOptimal`;
      const res = await this.$axios.$post(url, payload);
      if (res?.succeeded) {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.tn("success_added_card"),
          color: "success",
        });
        this.$router.push("/basket");
        setLoading(false);
        close();
      }
    },

    async onExportExcel() {
      this.$store.dispatch("SET_LOADING", true);
      const guid = this.$route.params.resultGuid;
      const url = `PriceListViewerProductSelection/${guid}/ExportNotFoundProductList`;
      await this.$axios.$postResFile(url);
      this.$store.dispatch("SET_LOADING", false);
    },

    onPermission() {
      this.isPermissionAlert = true;
      this.$nextTick(() => (this.isAddOptimalBasket = true));
    },

    onSelect(v) {
      this.isShowItems = false;
      this.itemData = { ...v };
      this.$nextTick(() => {
        if (v?.linkedProductGuid) {
          this.isShowItems = true;
          this.$refs.table3?.getEntries({ reset: true });
        }
      });
    },
  },
};
</script>

<template>
  <div>
    <AddOptimalBasketModal
      @close="isAddOptimalBasket = false"
      v-if="isAddOptimalBasket"
      :result-guid="$route.params.resultGuid"
      @accept="onAddOptimalBasket"
    />

    <PermissionConfirmDialog
      :text="$t('permission_confirm_dialog.function_permission_alert')"
      :try-count="tryCount"
      @close="isPermissionAlert = false"
      v-if="isPermissionAlert"
      @accept="onPermission"
    />

    <v-container fluid>
      <div class="page-wrap">
        <div
          :class="!isDrawer && 'left-side-closed'"
          class="left-side"
        >
          <ProviderList
            :isFocusable="isDrawer"
            class="left-side-list"
            ref="table1"
            :ln="ln + '.provider_list'"
            :tableHeight="tableHeight"
            @selectedRows="(v) => (selectedRows = v)"
            @refresh="onRefresh"
          />
        </div>

        <div
          class="content-side"
          :class="!isDrawer && 'left-side-closed'"
        >
          <price-list-items
            :GET_API="getResultItems"
            :entries="entriesByProduct"
            :entriesCount="entriesCountByProduct"
            @select="onSelect"
            :tableHeaders="tableHeaders"
            :ln="ln"
            :tableHeight="getTableHeight"
            :uniqKey="uniqKey"
            :url="urlResultItems"
            :isShowMyMarkup="false"
            hideBackBtn
            disableAutofocus
            disableTab
            ref="table2"
            hide-distributor-info
            :sort="false"
            :v-resize-table="{ tableHeaders: tableHeaders, callback: onUpdateTdSizes }"
          >
            <template #title>
              <div class="d-flex align-center">
                <v-btn
                  color="primary"
                  min-width="40"
                  width="40"
                  height="40"
                  min-height="40"
                  outlined
                  @click="isDrawer = !isDrawer"
                  :title="isDrawer ? tn('close') : tn('open')"
                >
                  <v-icon v-if="isDrawer"> mdi-arrow-left </v-icon>
                  <v-icon v-else> mdi-arrow-right </v-icon>
                </v-btn>
                <div class="py-6"></div>

                <div class="title _title pl-3">
                  {{ tn("title") }}
                </div>

                <v-spacer />

                <v-btn
                  class="transform-none"
                  height="40"
                  min-width="40"
                  width="40"
                  depressed
                  color="primary"
                  outlined
                  @click="onShowAddOptimalBasket"
                  :title="tn('add_optimal_basket_btn')"
                >
                  <v-icon> mdi-cart-check </v-icon>
                </v-btn>

                <div class="px-1" />

                <v-btn
                  class="transform-none"
                  height="40"
                  min-width="40"
                  width="40"
                  depressed
                  color="primary"
                  outlined
                  @click="onExportExcel"
                  :title="tn('export_excel_btn')"
                >
                  <v-icon> mdi-file-excel-outline </v-icon>
                </v-btn>
              </div>
            </template>

            <template #_add_btn="{ item }">
              <div class="d-flex justify-center">
                <v-btn
                  :title="tn('table.actions.add')"
                  min-height="28"
                  height="28"
                  min-width="28"
                  width="28"
                  depressed
                  color="transparent"
                  v-if="item.basketLineItemGuid"
                >
                  <v-icon> mdi-check-all </v-icon>
                </v-btn>
                <v-btn
                  :title="tn('table.actions.add')"
                  @click="onAdd({ item })"
                  min-height="28"
                  height="28"
                  min-width="28"
                  width="28"
                  depressed
                  color="transparent"
                  v-else
                >
                  <v-icon color="primary"> mdi-cart-outline </v-icon>
                </v-btn>
              </div>
            </template>

            <template #providerName="{ item }">
              <div class="d-flex mx-2">
                <exclusive-provider-btn
                  :ln="ln"
                  :item="item"
                />

                <div
                  class="text-left"
                  v-text="item.providerName"
                />
              </div>
            </template>
          </price-list-items>

          <ShowPharmacyRequirements
            v-if="isShowItems && isShowOrganizationStatistic != false"
            ref="table3"
            :tableHeight="tableHeight / 2.44"
            :productGuid="itemData?.linkedProductGuid"
            :amount="itemData?.amountDiscount || itemData?.amount"
            :uniqKey="ln"
            :isShowSearchBtn="false"
            @isHide="(e) => (isHide = e)"
          />
        </div>
      </div>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.tb-action-wrap-2 {
  padding-left: 10px;
}

.page-wrap {
  display: flex;
  height: 100%;

  .left-side {
    flex: 1;
    min-width: 400px;
    overflow-y: auto;
    transition: all 0.2s;

    &.left-side-closed {
      flex: 0;
      min-width: 0;

      .left-side-list {
        max-height: 50vh;
      }
    }
  }

  .content-side {
    flex: 3;
    overflow-y: auto;
    position: relative;

    padding-left: 10px;
    margin-left: -1px;

    &.left-side-closed {
      margin-left: -12px;
      padding-left: 12px;
    }
  }
}

._title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
