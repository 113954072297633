<script>
import { mapActions, mapGetters } from "vuex";
import debounce from "debounce";

import tnVue from "@/mixins/tn.vue";

import TableCog from "@/components/table-cog.vue";
import TableFocusInput from "./table-focus-input.vue";

export default {
  components: {
    TableCog,
    TableFocusInput,
  },

  props: {
    sortBy: String,
    tableHeight: Number,
    ln: String,
  },

  data: () => ({
    options: {},
    itemsPerPage: 50,

    loaders: {},

    selected: {},

    searchText: null,

    isToPrev: false,
    isSavePosition: false,

    filteredHeaders: [],

    cacheKey: "selection-auto-products-list",
  }),

  mixins: [tnVue],

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("selection-auto-result", {
      entries: "GET_PRODUCTS",
      entriesCount: "GET_PRODUCTS_COUNT",
    }),

    pagesCount: (vm) => Math.ceil(vm.entriesCount / vm.itemsPerPage),
    isLoading: (vm) => !!Object.keys(vm.loaders).length,

    tableHeaders: (vm) => [
      {
        text: "#",
        value: "number",
        width: 10,
        sortable: false,
        class: "disable-resize",
      },
      {
        text: vm.tn("table.productName"),
        value: "name",
        minWidth: "180px",
        _required: true,
      },
      {
        text: vm.tn("table.manufacturer"),
        value: "brandName",
        minWidth: "180px",
        class: "disable-resize",
      },
    ],
  },

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },

    selected: debounce(function () {
      const v = this.selected || {};
      this.$emit("select", { ...v });
    }, 200),
  },

  created() {
    this.searchText = this.$route.query.search;
  },

  mounted() {
    document.addEventListener("keyup", this.onKeyup);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.onKeyup);
  },

  methods: {
    ...mapActions("selection-auto-result", {
      getEntriesApi: "GET_PRODUCTS_API",
    }),

    async getEntries(ee) {
      const isReset = ee?.reset;

      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      const params = {
        options: {
          limit: this.options.itemsPerPage,
          offset: this.options.itemsPerPage * (this.options.page - 1),
          sortDirection: this.$getSort(this.options.sortDesc, true),
          sortBy: this.$getSort(this.options.sortBy),
          search: this.searchText,
          guid: this.$route.params.resultGuid,
        },
      };

      if (isReset) {
        this.options.page = 1;
        params.options.offset = 0;
      }

      await this.getEntriesApi(params);

      if (!this.checkFocused()) {
        this.setFocusToItem();
      } else {
        this.selected = {};
      }

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onKeyup(e) {
      if (e.keyCode == 113) {
        this.$refs.searchBox?.focus();
        this.$nextTick(() => {
          const input = this.$refs.searchBox?.$el?.querySelector("input");
          input?.select();
        });
      }
    },

    checkFocused() {
      return document.activeElement.className.includes(this.cacheKey);
    },

    setFocusToItem() {
      if (this.isSavePosition && this.selected?._id) {
        this.isSavePosition = false;
        const input = this.$setInputFocus(this.selected._id, this.cacheKey);
        if (!input) {
          this.isToPrev = true;
          this.setFocusToItem();
        }
      } else if (this.isToPrev) {
        this.isToPrev = false;
        const last = this.$lastArrayItem(this.entries);
        if (!last) return;
        this.selected = { ...last };
        this.$setInputFocus(this.selected._id, this.cacheKey);
      } else {
        const first = this.$firstArrayItem(this.entries);
        if (!first) return;
        this.selected = { ...first };
        this.$setInputFocus(this.selected._id, this.cacheKey);
      }
    },

    onSearch: debounce(function () {
      this.options.page = 1;
      this.$nextTick(() => this.getEntries());
    }, 200),

    onClear() {
      this.searchText = null;
      this.$nextTick(() => this.getEntries());
    },

    onPageUp() {
      if (this.options.page > 1) {
        this.isSavePosition = true;
        this.options.page--;
      }
    },

    onPageDown() {
      if (this.options.page < this.pagesCount) {
        this.isSavePosition = true;
        this.options.page++;
      }
    },

    onUpKey({ item }) {
      if (item._id == 1 && this.options.page > 1) {
        this.isToPrev = true;
        this.options.page--;
      } else {
        this.isToPrev = false;
        if (this.$setInputFocus(item._id - 1, this.cacheKey)) {
          const select = this.entries[item._id - 2];
          if (select) this.selected = { ...select };
        }
      }
    },

    onDownKey({ item }) {
      this.isToPrev = false;
      if (this.entries.length == item._id && this.options.page < this.pagesCount) {
        this.options.page++;
      } else if (this.$setInputFocus(item._id + 1, this.cacheKey)) {
        const select = this.entries[item._id];
        if (select) this.selected = { ...select };
      }
    },

    setFocusToTable() {
      this.isSavePosition = true;
      this.$nextTick(() => this.setFocusToItem());
    },

    onSelect({ item }) {
      this.selected = { ...item };
      this.$setInputFocus(item._id, this.cacheKey);
    },

    changeView() {
      const resultGuid = this.$route.params.resultGuid;
      this.$router.replace(`/selection/auto/result/${resultGuid}`);
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex align-center">
      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="changeView"
        :title="tn('show_by_provider_btn')"
        class="mr-2"
      >
        <v-icon> mdi-format-indent-increase </v-icon>
      </v-btn>

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="getEntries"
        :loading="isLoading"
        :title="tn('refresh_btn')"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>

      <div class="px-1" />

      <table-cog
        :headersData="tableHeaders"
        @apply="(v) => (filteredHeaders = $compareArray(tableHeaders, v, 'value'))"
        :cacheKey="cacheKey"
      />

      <div class="mx-1 py-6" />

      <v-text-field
        outlined
        hide-details
        dense
        append-icon="mdi-magnify"
        @click:append="onSearch"
        @keyup.enter="onSearch"
        @click:clear="onClear"
        class="w-100"
        :label="tn('search')"
        clearable
        v-model="searchText"
        ref="searchBox"
      />
    </div>

    <slot />

    <div class="mt-1" />

    <v-data-table
      v-if="filteredHeaders.length && tableHeight > 0"
      :headers="filteredHeaders"
      dense
      :height="tableHeight"
      :mobile-breakpoint="0"
      :loading="isLoading"
      :options.sync="options"
      :items="entries"
      :server-items-length="entriesCount"
      :items-per-page.sync="itemsPerPage"
      fixed-header
      must-sort
      :sort-by="sortBy"
      :footer-props="{
        itemsPerPageOptions: [25, 50],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: $t('table.per_page_text'),
      }"
      key="guid"
      class="table-footer-nowrap"
    >
      <template v-slot:item="{ item, headers, index }">
        <tr
          class="cursor-pointer"
          @click.stop="onSelect({ item })"
          :class="{
            'tr-active': selected.guid == item.guid,
          }"
        >
          <template v-for="head in headers">
            <td
              v-if="head.value == 'number'"
              :key="head.value"
              class="px-0 text-center"
            >
              <span
                class="text-nowrap"
                v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
              />
            </td>

            <td
              v-else-if="head._filter"
              :key="head.value + 1"
            >
              <div
                class="text-nowrap"
                v-if="head._html"
                v-html="head._filter(item[head.value], item)"
                :style="{ minWidth: head.minWidth }"
              />
              <div
                v-else
                class="text-nowrap"
                v-text="head._filter(item[head.value], item)"
                :style="{ textAlign: head._align, minWidth: head.minWidth }"
              />
            </td>

            <td
              v-else-if="head.value == 'name'"
              :key="head.value + 4"
            >
              <table-focus-input
                :ref="item.id"
                :tabindex="index + 1"
                :item="item"
                :uniKey="cacheKey"
                @upKey="onUpKey({ item })"
                @downKey="onDownKey({ item })"
                @pageUp="onPageUp({ item })"
                @pageDown="onPageDown({ item })"
              />

              <div class="d-flex">
                <v-btn
                  :loading="isLoading"
                  :color="item.isExclusiveProduct ? 'primary' : undefined"
                  :title="item.isExclusiveProduct ? tn('table.actions.exclusive') : tn('table.actions.not_exclusive')"
                  icon
                  x-small
                  class="mr-1"
                >
                  <v-icon>
                    {{ item.isExclusiveProduct ? "mdi-message-plus" : "mdi-message-outline" }}
                  </v-icon>
                </v-btn>

                <div
                  :style="{ minWidth: head.minWidth }"
                  v-text="item[head.value]"
                />
              </div>
            </td>

            <td
              v-else
              :key="head.value + 4"
            >
              <div
                :style="{ minWidth: head.minWidth }"
                v-text="item[head.value]"
              />
            </td>
          </template>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss" scoped>
.translate-3 {
  transform: translateY(-4px);
}
</style>
