<script>
import { mapActions, mapGetters } from "vuex";
import debounce from "debounce";

import tnVue from "@/mixins/tn.vue";

import TableCog from "@/components/table-cog.vue";
import TableFocusInput from "./table-focus-input.vue";

export default {
  components: { TableCog, TableFocusInput },

  props: {
    tableHeight: Number,
    ln: String,
    isFocusable: Boolean,
  },

  data: () => ({
    options: {},
    itemsPerPage: 50,

    loaders: {},

    selected: {},
    selectedRows: [],

    searchText: null,

    isToPrev: false,
    isSavePosition: false,

    filteredHeaders: [],
    cacheKey: "selection-auto-result-provider-list",
  }),

  mixins: [tnVue],

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("selection-auto-result", {
      entries: "GET_PROVIDERS",
      entriesCount: "GET_PROVIDERS_COUNT",
    }),

    pagesCount: (vm) => Math.ceil(vm.entriesCount / vm.itemsPerPage),
    isLoading: (vm) => !!Object.keys(vm.loaders).length,

    tableHeaders: (vm) => [
      // {
      //   text: "№",
      //   value: "number",
      //   width: 10,
      //   sortable: false,
      //   class: "disable-resize",
      // },
      {
        text: vm.tn("table.providerName"),
        value: "providerName",
        minWidth: "180px",
        _required: true,
      },
      {
        text: vm.tn("table.totalAmount"),
        value: "amount",
        _filter: (v) => vm.$sum(v),
        _required: true,
        class: "disable-resize",
      },
      {
        text: vm.tn("table.priceTypeName"),
        value: "priceTypeName",
        width: vm.$dxcs.priceTypeName,
        _required: true,
      },
      {
        text: vm.tn("table.itemsCount"),
        value: "itemsCount",
        _filter: (v) => vm.$sum(v),
        _required: true,
      },
    ],

    realHeaders: (vm) => [
      {
        width: 10,
        class: "disable-resize disable-tr-size",
        value: "data-table-select",
      },
      ...vm.filteredHeaders,
    ],

    actionsList: (vm) => [
      {
        name: vm.tn("actions.info_btn"),
        action: (item) => vm.$windowOpen("price-list-provider", item.providerGuid, item.providerName, 650, 800, "preview=1"),
      },
    ],
  },

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },

    selectedRows(v = []) {
      this.$emit("selectedRows", [...v]);
    },
  },

  mounted() {
    document.addEventListener("keyup", this.onKeyup);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.onKeyup);
  },

  methods: {
    ...mapActions("selection-auto-result", {
      getEntriesApi: "GET_PROVIDERS_API",
    }),

    async getEntries(ee) {
      const isReset = ee?.reset;

      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      const params = {
        options: {
          limit: this.options.itemsPerPage,
          offset: this.options.itemsPerPage * (this.options.page - 1),
          //sortDirection: this.$getSort(this.options.sortDesc, true),
          //sortBy: this.$getSort(this.options.sortBy),
          search: this.searchText,
          guid: this.$route.params.resultGuid,
        },
      };

      if (isReset) {
        this.options.page = 1;
        params.options.offset = 0;
      }

      await this.getEntriesApi(params);

      if (!this.checkFocused()) {
        this.setFocusToItem();
        // if (!this.selectedRows.length && this.entries.length) {
        //   this.$refs.table?.select(this.entries[0]);
        // }
      } else {
        this.selected = {};
      }

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    checkFocused() {
      return document.activeElement.className.includes(this.cacheKey);
    },

    setFocusToItem() {
      if (this.isSavePosition && this.selected?._id) {
        this.isSavePosition = false;
        const input = this.$setInputFocus(this.selected._id, this.cacheKey);
        if (!input) {
          this.isToPrev = true;
          this.setFocusToItem();
        }
      } else if (this.isToPrev) {
        this.isToPrev = false;
        const last = this.$lastArrayItem(this.entries);
        if (!last) return;
        this.selected = { ...last };
        this.$setInputFocus(this.selected._id, this.cacheKey);
      } else {
        const first = this.$firstArrayItem(this.entries);
        if (!first) return;
        this.selected = { ...first };
        this.$setInputFocus(this.selected._id, this.cacheKey);
      }
    },

    onSearch: debounce(function () {
      this.options.page = 1;
      this.$nextTick(() => this.getEntries());
    }, 200),

    onKeyup(e) {
      if (e.keyCode == 113) {
        this.$refs.searchBox?.focus();
        this.$nextTick(() => {
          const input = this.$refs.searchBox?.$el?.querySelector("input");
          input?.select();
        });
      }
    },

    onClear() {
      this.searchText = null;
      this.$nextTick(() => this.getEntries());
    },

    onPageUp() {
      if (this.options.page > 1) {
        this.isSavePosition = true;
        this.options.page--;
      }
    },

    onPageDown() {
      if (this.options.page < this.pagesCount) {
        this.isSavePosition = true;
        this.options.page++;
      }
    },

    onUpKey({ item }) {
      if (item._id == 1 && this.options.page > 1) {
        this.isToPrev = true;
        this.options.page--;
      } else {
        this.isToPrev = false;
        if (this.$setInputFocus(item._id - 1, this.cacheKey)) {
          const select = this.entries[item._id - 2];
          if (select) this.selected = { ...select };
        }
      }
    },

    onDownKey({ item }) {
      this.isToPrev = false;
      if (this.entries.length == item._id && this.options.page < this.pagesCount) {
        this.options.page++;
      } else if (this.$setInputFocus(item._id + 1, this.cacheKey)) {
        const select = this.entries[item._id];
        if (select) this.selected = { ...select };
      }
    },

    onKeyUpEnter({ item }) {
      const isSelected = this.$refs.table?.isSelected(item);
      this.$refs.table?.select(item, !isSelected);
    },

    setFocusToTable() {
      this.isSavePosition = true;
      this.$nextTick(() => this.setFocusToItem());
    },

    onSelect({ item }) {
      this.selected = { ...item };
      this.$setInputFocus(item._id, this.cacheKey);
    },

    onClickRow(item, params) {
      if (item && params) {
        this.onSelect({ item });
        this.onKeyUpEnter({ item });
      }
    },

    changeView() {
      const resultGuid = this.$route.params.resultGuid;
      this.$router.replace(`/selection/auto/result/${resultGuid}/product`);
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex align-center">
      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="changeView"
        :title="tn('show_by_product_btn')"
        class="mr-2"
      >
        <v-icon> mdi-format-indent-decrease </v-icon>
      </v-btn>

      <v-spacer />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="getEntries"
        :disabled="isLoading"
        :loading="isLoading"
        :title="tn('refresh_btn')"
        class="mr-2"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>

      <table-cog
        :headersData="tableHeaders"
        @apply="(v) => (filteredHeaders = $compareArray(tableHeaders, v, 'value'))"
        :cacheKey="cacheKey"
      />

      <div class="mx-1 py-6" />

      <v-text-field
        outlined
        hide-details
        dense
        append-icon="mdi-magnify"
        @click:append="onSearch"
        @keyup.enter="onSearch"
        @click:clear="onClear"
        class="w-100"
        :label="tn('search')"
        clearable
        v-model="searchText"
        ref="searchBox"
      />
    </div>

    <slot />

    <div class="mt-1" />

    <v-data-table
      ref="table"
      v-if="filteredHeaders.length && tableHeight > 0"
      :headers="realHeaders"
      dense
      :height="tableHeight"
      :mobile-breakpoint="0"
      :loading="isLoading"
      :options.sync="options"
      :items="entries"
      :server-items-length="entriesCount"
      :items-per-page.sync="itemsPerPage"
      fixed-header
      disable-sort
      :footer-props="{
        itemsPerPageOptions: [25, 50],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: $t('table.per_page_text'),
      }"
      key="id"
      show-select
      v-model="selectedRows"
      item-key="id"
      class="remove-checkbox-margin"
      checkbox-color="primary"
      @click:row="onClickRow"
    >
      <template v-slot:item.providerName="{ item, header, index }">
        <table-focus-input
          v-if="isFocusable"
          :ref="item.id"
          :tabindex="index + 1"
          :item="item"
          :uniKey="cacheKey"
          @upKey="onUpKey({ item })"
          @downKey="onDownKey({ item })"
          @pageUp="onPageUp({ item })"
          @pageDown="onPageDown({ item })"
          @enter="onKeyUpEnter({ item })"
        />
        <div
          class="d-flex align-center"
          :style="{ minWidth: header.minWidth }"
        >
          <v-menu
            offset-y
            offset-x
            nudge-left="5"
            nudge-top="5"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                min-height="28"
                height="28"
                min-width="28"
                width="28"
                depressed
                color="transparent"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-menu </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <template v-for="(actionItem, index) in actionsList">
                <v-list-item
                  :key="index"
                  @click="actionItem.action(item)"
                >
                  <v-list-item-title :class="actionItem.class">
                    {{ actionItem.name }}
                  </v-list-item-title>
                </v-list-item>
                <v-divider
                  v-if="index < actionsList.length - 1"
                  :key="index + 'i'"
                />
              </template>
            </v-list>
          </v-menu>

          <div class="d-flex">
            <v-btn
              :loading="isLoading"
              :color="item.isExclusiveProvider ? 'primary' : undefined"
              :title="item.isExclusiveProvider ? tn('table.actions.exclusive') : tn('table.actions.not_exclusive')"
              icon
              x-small
              class="mr-1"
            >
              <v-icon>
                {{ item.isExclusiveProvider ? "mdi-message-plus" : "mdi-message-outline" }}
              </v-icon>
            </v-btn>

            <span>{{ item.providerName }}</span>
          </div>
        </div>
      </template>

      <template v-slot:item.itemsCount="{ item, header }">
        <div
          class="text-nowrap text-right"
          v-html="header._filter(item[header.value], item)"
          :style="{ minWidth: header.minWidth }"
        />
      </template>

      <template v-slot:item.amount="{ item, header }">
        <div
          class="text-nowrap text-right"
          v-html="header._filter(item[header.value], item)"
          :style="{ minWidth: header.minWidth }"
        />
      </template>
    </v-data-table>
  </div>
</template>
